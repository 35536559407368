.header-wapper {
    .header {
        .logo {
            img {
                width: 180px;
            }
        }
        .search {
            padding-top: 5px;
        }
    }
    .top-menu {
        .nav-item{
            padding: 0 0.5rem;
        }
    }
}