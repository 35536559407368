// Plugin CSS
// Init main SCSS File
@import "/public/assets/scss/style.scss";

#root {
  height: auto !important;
}

.home_link {
  cursor: pointer !important;
}

.header-wapper {
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.07);
  position: fixed;
  width: 100%;
  z-index: 99;
  background: #FFF;
}
.div_contents{margin-top: 85px;}

.footer {
  background: #000000;
  padding: 60px 0 20px 0;
  color: #FFF;
  position: inherit;

  a:hover {
    text-decoration: none
  }

  .copyright_right {
    text-align: right;
  }

  .heding {
    border-bottom: 1px solid #ebebeb40;
    padding: 0 0 6px;
  }

  .list_menu {
    line-height: 30px;

    svg {
      margin-right: 5px;
      color: #333f4d;
      transition: 0.4s;
      padding-bottom: 3px;
    }

    a:hover {
      svg {
        color: #50af9b;
      }
    }
  }

  .ft-social {
  }
}

.about-left {
  padding-left: 0 !important;
}

.border-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  color: #ebebeb;
}

.breadcrumb {
  padding: 0.75rem 0 !important;
  margin: 5px 0;
  background: none !important;
}

.navbar .nav-link {
  color: #444 !important;
  font-weight: bold;
}

.menu_home {
  width: 100%;
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: baseline;
}

.navbar .nav-item {
  // padding-left: 1rem !important;
  // padding-right: 1rem !important;
}

.navbar .nav-item {
  .dropdown-menu {
    padding: 0;
    .dropdown-item {
      padding: 10px;
    }
  }
}

.navbar .nav-link:hover {
  color: #2196f3 !important;
}

#root {
  overflow-x: hidden
}

.Home_adv_box_img__whDcp img {
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}

.partner_home {
  background: #FFF;
  margin-bottom: -40px;
  padding: 20px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, .07);
}

.service-items {
  display: flex;
  flex-wrap: wrap;

  .service-item {
    flex: 1;
    margin: 5px 5px 50px 5px;
    background: #ffffff;
    box-shadow: 0px 3px 20px rgba(0, 33, 71, 0.06);
  }
}

.nav-item input {
  border: 0 !important;
}

.cart-page, .checkout-page {
  margin-top: 45px !important;
}

.btn {
  border-radius: 0 !important;
}

.navbar-expand-lg .navbar-collapse {
  padding: 10px;
}

section {
  width: 100%;
}

.bg-gray {
  background: #afd7fa26
}

.box-content {
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, .07);
  padding: 30px 20px;
  margin: 0 0 30px 0;
  line-height: 28px;
}

.navbar {
  padding: 0.5rem 0 !important;
}

.hed_cart {
  display: block;
  margin-top: 5px;
}

.cart-wrapper .cart-inner .cart i {
  font-size: 18px !important;
}

.product-card {
  margin: 5px;

  .card-title {
    min-height: 50px;
  }
}

.list_product {
  margin-bottom: 10px;
}

.btn-success, .btn-primary, .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background: #0066cc !important;
  border-color: #0066cc !important;
}

.product_cate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  h3 {
    color: #0066cc;
  }

  a {
    border: 0;
    margin-bottom: 5px;
    font-weight: 600;
  }
}

.all-product .product_more {
  display: none;
}

.user_menu_sub {
  box-shadow: rgba(0, 33, 71, 0.06) 0px 3px 20px;
  border: 1px solid #e4e2e2 !important;
  z-index: 999999;
}

.bg-primary {
  background-color: #0066cc !important;
}

.list-group-item.active {
  background-color: #0066cc !important;
  border-color: #0066cc !important;
}

.menu_footer {
  padding: 1.875rem 0 2.5rem 0;

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    li {
      list-style: none;

      a {
        text-transform: uppercase;
        color: #FFF;
        text-decoration: underline;
      }
    }
  }
}
.auto-complete-search .searching-result{
  background: #FFF !important;
}
.auto-complete-search .searching-result a {
  color: #555;
  border-bottom: 1px solid #ebebeb;
  padding: 2px 0;
}
.mohinh_mb,.mohinh{position: relative;}
.link-thietbi{
  width: 45%;
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.link-hotro{
  width: 55%;
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
.link-thietbi_mb{
  width: 100%;
  display: block;
  height: 40%;
  position: absolute;
  top: 0;
}
.link-hotro_mb{
  width: 100%;
  display: block;
  height: 60%;
  position: absolute;
  bottom: 0;
}
.form-control{padding: 0.20rem 0.75rem!important;}
.Home_section_model__adGXe{padding-top:10px}
.app_icon_footer{
  div{display: flex; align-items: center;}
}
.mh-home{z-index: 1;position: relative;}
.mohinh{margin-top: -50px;}
.popup_home{
  .modal-dialog{
    max-width:70% !important;
    top:10% !important;
    @media screen and (max-width: 767px) {
      & {
        max-width:100% !important;
        top:20% !important;
      }
    }

    .close{
      position: absolute;
      right: 10px;cursor: pointer;
    }
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1280px !important;
  }
}

@media screen and (max-width: 767px) {
  .header-wapper {
    margin-bottom: 60px;
  }
  .footer {
    .copyright_right, .copyright_left {
      text-align: center;
    }
    .ft-social{text-align: center;}
    .about-left {
      padding-left: 20px !important;
    }
  }
  .service-items {
    display: unset;
    flex-wrap: wrap;

    .service-item {
      margin: 20px;
      padding: 0 0 10px;
    }
  }
  .header {
    top: 0;
    background-color: #fff;
    box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.15);
    position: fixed;
    z-index: 100;

    img {
      max-height: 60px;
      width: auto !important;
      padding: 10px 0;
    }
  }
  .banner-inner {
    img {
      display: none;
    }
  }
  .product_cate h3 {
    font-size: 24px !important;
  }
  .Home_section_title__qe4hq {
    font-size: 25px !important;
    padding: 0 !important;
  }
  .product-card .card-title {
    padding: 0 5px !important;
  }
  .product-card {
    padding: 0 !important;
  }
  .product_cate {
    margin: 0 5px !important;
  }
  .nav-item input {
    border: 1px solid #dee2e6 !important;
  }
  .box-content {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
  .breadcrumb {
    padding: 0.75rem 0 !important;
    margin: 0 !important;
    background: none !important;
    font-size: 15px !important;
  }
  .cart-page, .checkout-page {
    margin-top: 75px !important;
  }
  .menu_footer {
    padding: 0 10px;
    ul {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding: 0;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      li{
        padding: 5px;
      }
    }
  }
  .product_cate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    align-items: center;
    h3 {
      color: #0066cc;
      font-size: 18px !important;
    }

    a {
      border: 0;
      width: 190px;font-weight: unset;text-align: right;
    }
  }
  .Home_section_model__adGXe{padding-top:20px}
  .Home_partner__vfk2m{
    h2{padding: 20px 0 !important;}
  }
  .app_icon_footer{
    display: flex;
    justify-content: space-between;
    div{display: block !important; text-align: center;}
  }
}

.topnav {
  overflow: hidden;
  z-index: 99999;
  position: relative;
  a {
    float: left;
    display: block;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
  }

  .dropdown {
    float: left;
    overflow: hidden;
  }

  .dropdown .dropbtn {
    font-size: 17px;
    border: none;
    outline: none;
    color: white;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
    padding: 10px 15px
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  .dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }


  .dropdown-content a:hover {
    background-color: #ddd;
    color: black;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

}
@media screen and (max-width: 600px) {
  .topnav a:not(:first-child), .dropdown .dropbtn {
    display: none;
  }
  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {position: relative;}
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  .topnav.responsive .dropdown {float: none;}
  .topnav.responsive .dropdown-content {position: relative;}
  .topnav.responsive .dropdown .dropbtn {
    display: block;
    width: 100%;
    text-align: left;
  }
}