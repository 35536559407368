.main-menu {
    .transition {
        transition: 0.1s;
        padding-right: 1.5rem;
    }
    .auto-complete-search {
        margin-top: 3px;
        width: 0;
        transition: 0.1s;
    }
    .search-box-activated {
        .auto-complete-search {
            transition: 0.1s;
            width: 200px;
            max-width: 100%;
        }
        .transition {
            transition: 0.1s;
            padding-right: 0.82rem;
        }
    }
    .dropdown {
        &:hover .dropdown-menu {
            display: block;
        }
    }
    @media screen and (max-width: 768px) {
        .search-box-activated {
            .auto-complete-search {
                width: 100%;
            }
        }
    }
}
.mobile-main-menu-wrapper {
    position: fixed !important;
    top: 0;
    left: -120%;
    height: 100%;
    z-index: 5;
    width: 100%;
    transition: 0.1s;
    .background {
        background: #555555;
        opacity: 0.6;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .main-menu {
        background: #FFFFFF;
        z-index: 10;
        position: absolute;
        top: 0;
        width: 70%;
        height: 100%;
    }
}
.mobile-main-menu-wrapper.active {
    left: -15px;
}
