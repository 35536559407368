.auto-complete-search {
    border-radius: 4px;
    position: relative;
    input.auto-complete-search-input {
        font-size: 0.8em;
        border: none;
        outline: none;
        outline-width: 0;
        background: transparent;
    }
    button:focus,
    input:focus {
        box-shadow: none !important;
    }
    .searching-result {
        z-index: 2;
        width: 100%;
        border: 1px solid #eee;
        position: absolute;
        padding: 10px 5px;
        background: transparent;
        font-size: 0.8rem;
        .card {
            cursor: pointer;
        }
        .img-fluid {
            width: 40px;
        }
        a {
            color: #555;
        }
        a:hover{
            text-decoration: none;
        }
    }
}